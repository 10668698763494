import {Storage} from  '../storage/Storage'
import {CollectionToObject} from  '../storage/Storage'
import {Gate} from '../libs/gate'

export const Lead = {
	get : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/lead',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					Storage.dispatch({
						type: 'load',
						for : 'Lead',
						items: CollectionToObject(response.model)
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	}
}
