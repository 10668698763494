import * as React from 'react';

export class Aside extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<aside className="aside-menu">
				<div className="button-aside-menu"></div>
				<ul>
					<li><a href="#" className="new-file">New file</a></li>
					<li><a href="#" className="list">Projects List</a></li>
					<li><a href="#" className="chat">Chat</a></li>
					<li><a href="#" className="statistic">Graphics</a></li>
					<li><a href="#" className="settings">Settings</a></li>
				</ul>
			</aside>)
		}
	}

