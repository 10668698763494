import * as React from 'react';
import {Link} from 'react-router-dom'

export class UserListItem extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	render() {
		var dateTime = new Date(this.props.date);
		var dateDisplay = dateTime.getFullYear()+'-' + dateTime.getMonth()+'-'+dateTime.getDate()+' '+dateTime.getHours()+':'+dateTime.getSeconds();
		return (
			<tr className={this.props.active ? 'active':''}>
				<td className="field-id"><Link to={'/user/'+this.props.id}>{this.props.id}</Link></td>
				<td className="field-user-name">{this.props.profile.lastName+' '+this.props.profile.name}</td>
				<td className="field-lead-email"><a href={"mailto:" + this.props.email}>{this.props.email}</a></td>
				<td className="role"><span className="processed">{this.props.role}</span></td>
			</tr>
		)
	}
}
