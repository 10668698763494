import * as React from 'react';
import {Project} from "../../actions/project";
import {connect} from "react-redux";
import {ProjectListItem} from "../project/ProjectListItem";
import {PopupsCollection} from "../../containers/parts/Popups";
import {AddProject} from "./AddProject";

PopupsCollection.add('AddProject',AddProject);

class LeadListComponent extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	addProject = () =>{
		PopupsCollection.activate('AddProject');
	}

	loading = (
		<div className="loading">
			все пропало
		</div>
	);

	render() {
		console.log(this.props);
		if(!this.props.lastUpdate){
			Project.get();
			return this.loading;
		}
		var items = [];
		var current = parseInt(this.props.location.pathname.replace('/project/','')); //TODO: так себе
	    for (let id in this.props.projects){
	    	var data =  Object.assign({},this.props.projects[id],{
	    		active : id == current
	    	}) ;
	    	items.push(React.createElement(ProjectListItem,data));
	    }
		return (
			<section id="projects-list">
				<ul className="controls">
					<li><button className="icon-user-plus small-btn" onClick={this.addProject}></button></li>
				</ul>
			<table id="leads-table">
			<thead>
				<tr>
					<th className="field-id">ID</th>
					<th className="field-lead-name">Lead Name</th>
					<th className="field-lead-phone">Date</th>
					<th className="field-lead-email">Email</th>
					<th className="manager">Manager Name</th>
					<th className="status">Status</th>
				</tr>
			</thead>
			<tbody>
				{items}
				<tr>
					<td className="field-id">1</td>
					<td className="field-lead-name">Dmitriy Kagan</td>
					<td className="field-lead-phone"><a href="tel:+972523801863">+972523801863</a></td>
					<td className="field-lead-email"><a href="mailto:dk@72dpi.co.il">dk@72dpi.co.il</a></td>
					<td className="manager">Elena Biletskaya</td>
					<td className="status"><span className="processed">Processed</span></td>
				</tr>
				<tr>
					<td className="field-id">2</td>
					<td className="field-lead-name">Bar Golan</td>
					<td className="field-lead-phone"><a href="tel:09500000">09500000</a></td>
					<td className="field-lead-email"><a href="mailto:bar@goodmedia.co.il">bar@goodmedia.co.il</a></td>
					<td className="manager"><a href="#" className="assign-manager">Assign manager</a></td>
					<td className="status"><span className="not-processed">Not processed</span></td>
				</tr>
				<tr>
					<td className="field-id">2</td>
					<td className="field-lead-name">Bar Golan</td>
					<td className="field-lead-phone"><a href="tel:09500000">09500000</a></td>
					<td className="field-lead-email"><a href="mailto:bar@goodmedia.co.il">bar@goodmedia.co.il</a></td>
					<td className="manager"><a href="#" className="assign-manager">Assign manager</a></td>
					<td className="status"><span className="active">Active</span></td>
				</tr>
			</tbody>
		</table>
			</section>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		projects : state.projects.items,
		lastUpdate : state.projects.lastUpdate
	};
}

export const ProjectsList = connect(mapStateToProps)(LeadListComponent)
