import * as React from 'react';
import { connect } from 'react-redux'
import {FileList} from '../file/FileList'
import {DefinitionList} from '../../containers/Definition/DefinitionList';

const basePatern = '/project/{id}/';

class SingleProjectComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	details(){
		return(
			<div className="item-section">
				<DefinitionList open={true} title ="Details:" items={this.props.project.additional}/>
				<DefinitionList open={true} title ="Details:" items={this.props.project.additional}/>
			</div>
		)
	}

	render() {
		var id =this.props.match.params.id;
		var base = basePatern.replace('{id}',id);
		var project = Object.assign({},this.props.project);
		delete project['files'];
		delete project['additional'];
		delete project['browser'];
		delete project['utm'];
		delete project['id'];
		//TODO : временная заглушка
		if(!this.props.project){
			return (<section className="info-section"></section>)
		}
		return (
				<section className="info-section">
					<form action="post">
						<header className="info-section-headline">
							<div className="action-bar">
								<div className="select-field">
									<select name="task-options" id="project-status">
										<option value="">Change status</option>
										<option value="active">Active</option>
										<option value="processed">Processed</option>
										<option value="not-processed">Not processed</option>
									</select>
								</div>
								<a href="#" className="chat small-btn">Chat</a>
								<a href="#" className="edit small-btn">Edit</a>
							</div>
							<h2 className="user-name">{this.props.project.name}</h2>
						</header>
						<div className="issue-info-item details">
							<DefinitionList title	="Main inforamation:" items={project} className="browser-info" twoColumns={true}/>
							{this.details()}
							<FileList files={this.props.project.files} base={base}/>
							<DefinitionList title="Browser info:" items={this.props.project.browser} className="browser-info"/>
							<DefinitionList title="Utm:" items={this.props.project.utm}/>
							<div className="item-section">
								<div className="item-header opener">
									<h3>Main information:</h3>
								</div>
								<div className="item-body">
									<h2>Project Title</h2>
									<ul className="details-list">
										<li>
											<div className="item-label">
												<span>Client name:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Project Status:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Date Start:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Date End:</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="item-section">
								<div className="item-header opener">
									<h3>Client info:</h3>
								</div>
								<div className="item-body">
									<ul className="details-list">
										<li>
											<div className="item-label">
												<span>Name:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Email:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Phone:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Location / Time zone:</span>
											</div>
										</li>
										<li>
											<div className="item-label">
												<span>Note:</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="item-section">
								<div className="item-header opener">
									<h3>Project Technocal Requirments and description:</h3>
								</div>
								<div className="item-body">
									<h4>Requirements:</h4>
									<ul className="requirements-list">
										<li>HTML5/CSS3</li>
										<li>React</li>
										<li>JavaScript</li>
										<li>Web Accessibility</li>
										<li>WordPress</li>
									</ul>
									<h4>Desction:</h4>
									<p>WooCommerce project on react. сайт на двух языках, английский и иврит. требуется ltr и rtl и мультиязычность. иврит основной. страницы сайта: главная, о нас, связь с нами, сервисное обслуживание клиентов, блог, список филиалов, условия пользования, возврат и доставка</p>
									<p>в части магазина будут следущие страницы: главная страница магазина, категория, продукт, логин, регистрация, личный эккаунт: история заказов и кол-во набранных баллов, личные данные и адрес, список личных купонов. корзина, чекаут</p>
									<p>"оплата кредиткой (транзила) и пейпал. данные транзиллы можно взять на нынешнем сайте. там же есть плагин"</p>
									<h4>Technical details to do:</h4>
									<table>
										<tr>
											<td className="tz-item">"полный accessibility не нужен. делать на основе плагина enable"</td>
											<td className="tz-status not-done">
												<span>Not done</span>
												<a href="#" className="change-status">Change</a>
											</td>
											<td className="assign-to-task"><a href="#">Assign to task</a></td>
										</tr>
										<tr>
											<td className="tz-item">очень важно чтобы все страницы сайта быстро загружались. время загрузки не должно превышать 4 сек</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">создание 301 скрипта на основе присланного списка</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">у каждого цвета продукта будет своя галерея как на gittabags.com. по клику на картинку цвета картинки в слайдере меняются на те что принадлежат выбранному цвету. все цвета это вариации продукта</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">цены в двух языковых версиях не будут связаны между собой и не будут назначаться по курсу валют. цены будут назначаться по отдельности в каждом языке</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">"подключение к наружной системе типа OTECH для синхронизации запасов товара на складе с их кол-вом на сайте. после заказа заказанные товары будут списаны со склада и кол-во товара на сайте будет синхронизировано с данными на складе. продажи осуществленные через физический магазин будут учтены также только при синхронизации после заказа на сайте."</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">подключение к наружной системе типа OTECH для синхронизации клуба покупателей. каждый юзер который сделает заказ через магазин будет внесен в список членов клуба в системе. так что если он придет в физический магазин то его данные будут уже существовать в общей системе. и наоборот, покупатель, вступивший в клуб через физический магазин сможет залогиниться на сайте при помощи идентификационного номера клуба либо через номер паспорта без надобности регистрироваться вновь. при регистрации надо добавить поле номер паспорта. при попытке регистрации с использованием номера паспорта существующего в системе юзеру будет выдано сообщение о том что такой номер уже существует и будет предложено залогиниться. при этом можно будет восстановить пароль который придет на указанный в системе мейл</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">разработка собирания баллов. при заказе, определенный в админке, процент от общей стоимости переконвертируется в баллы и сохранится на счету клиента. клиент всегда сможет увидеть кол-во баллов в своем эккаунте. при достижении определенной суммы баллов, определенной также в админке, эти баллы автоматически генерируются в купон-скидку на сумму эквивалентную собранным баллам. после это сбор баллов начинается заново, а купон сохраняется на счету клиента. он может просмотреть код этого и других не испольованных купонов в своем эккаунте. информация о сгенерированном купоне тажке будет выслана на мейл. а в мейле приходящем клиенту после заказа будет указано сколько баллов добавилось после этого заказа и сколько всего баллов есть у него на данный момент. кол-во купонов не ограничено. но каждый из них одноразовый. купить с таким купоном можно только на сайте.</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">"разработка расширяющая механизм отсылки мейлов после осуществления заказа. после того как покупатель завершит заказ ему будут высланы следущие мейлы:
												<ul>
													<li>- мейл после регистрации, который будет выслан после завершения регистрации на сайте или в физическом магазине и в котором будут общие данные и данные доступа к эккаунту на сайте</li>
													<li>- мейл ""ваш заказ принят"", который будет выслан сразу после завершения заказа ив котором будут общие данные о заказе и о начисленных баллах и о том сколько баллов всего есть на счету</li>
													<li>- мейл ""ваш заказ в обработке"" который будет выслан после того как заказ будет успешно оплачен и в котором будет сообщено что оплата принята и заказ принят в обработку</li>
													<li>- мейл ""ваш заказ выслан"" который будет выслан после того как заказ будет передан в службу доставки (смена статуса заказа админом) и в котором будет написано что заказ обработан и выслан</li>
													<li>- мейл ""ваш заказ готов"" в том случае если клиент выбрал в качестве способа доставки ""заберу сам"" (смена статуса заказа админом) в котором будет информация о месте где можно забрать заказ."</li>
												</ul>
											</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">"в корзине будут предложены upsale товары на основе плагина Smart Offers который есть на нынешнем сайте <a href="https://www.storeapps.org/product/smart-offers/">https://www.storeapps.org/product/smart-offers/</a></td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">гифт кард на основе плагина подогнанного под дизайн и нужды сайта</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">деление каталога на категории, аттрибуты и теги по которым можно будет фильтровать и сортировать</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">в магазине в верхнем слайдере можно показывать текст на фоне картинок или видео</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">на странице магазина показаны баннеры категорий и продуктов</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">в эккаунте юзера будут показаны его данные, адрес и история заказов</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">продукт можно будет добавить в список wishlist как тут https://sabinamusayev.com/pages/wishlist функционал можно базировать на основе подходящего плагина</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">весь контент показанный на странице продукта набивается на уровне редактирования продукта</td>
											<td className="tz-status">Not done</td>
										</tr>
										<tr>
											<td className="tz-item">форма связи на странице продукта отсылается с указанием названия продукта со страницы которого было выслано сообщение</td>
											<td className="tz-status">Not done</td>
										</tr>
									</table>
								</div>
							</div>
							<div className="item-section">
								<div className="item-header opener">
									<h3>Changes log:</h3>
								</div>
								<div className="item-body">
									<ul className="changes-list">
										<li>
											<span className="date">07.12.2017 - 12:01</span>
											<p><a href="#">Dmitriy Biletskiy</a> changed email from d@72dpi.co.il to dk@72dpi.co.il</p>
										</li>
										<li>
											<span className="date">07.12.2017 - 12:01</span>
											<p><a href="#">Dmitriy Biletskiy</a> added whatsapp</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</form>
				</section>
			);
		}
}


const mapStateToProps = (state , ownProps) => {
	return {
		project: state.projects.items[ownProps.match.params.id]
	};
}

export const SingleProject = connect(mapStateToProps)(SingleProjectComponent);

