const DefaultLeadState ={
	filters : {

	},
	items : [],
	lastUpdate : false
}
export function Lead(state = {} , action){
	if(action.for == 'Lead'){
		switch (action.type) {
			case 'load':
				return Object.assign({}, state, {
					items:action.items,
					lastUpdate : new Date()
				});
				return state;
		}
	}
	state = Object.assign({},DefaultLeadState,state)
	return state;
};