import * as React from 'react';

export class NotFound extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<section className="tasks">
			<h1>NotFound</h1>
			</section>
		)
	}
}