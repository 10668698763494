const DefaultUsersState ={
	filters : {

	},
	items : [],
	lastUpdate : false
}
export function Users(state = {} , action){
	if(action.for == 'Users'){
		switch (action.type) {
			case 'load':
				return Object.assign({}, state, {
					items:action.items,
					lastUpdate : new Date()
				});
			case 'updateSingle':{
				let temp = {};
				temp[action.item.id] = action.item;
				return Object.assign({}, state, {
					items:Object.assign({},state.items,temp)
				});
			}
		}
		return state;
	}
	state = Object.assign({},DefaultUsersState,state)
	return state;
};