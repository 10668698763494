import * as React from 'react';

export class Info extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<section className="info-section">
		<form action="post">
			<header className="info-section-headline">
				<div className="action-bar">
					<div className="select-field">
						<select name="task-options" id="lead-status">
							<option value="">Change status</option>
							<option value="active">Active</option>
							<option value="processed">Processed</option>
							<option value="not-processed">Not processed</option>
						</select>
					</div>
					<a href="#" className="chat small-btn">Chat</a>
					<a href="#" className="edit small-btn">Edit</a>
				</div>
				<h2 className="user-name">Dmitriy Kagan</h2>
			</header>
			<div className="issue-info-item details">
				<div className="item-section">
					<div className="item-header opener">
						<h3>Main inforamation:</h3>
					</div>
					<div className="item-body">
						<ul className="details-list two-columns">
							<li>
								<div className="item-label">
									<span>Phone:</span>
								</div>
								<div className="item-description">
									<span><a href="tel:+972523801863">+972523801863</a></span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Email: </span>
								</div>
								<div className="item-description">
									<span><a href="mailto:dk@72dpi.co.il">dk@72dpi.co.il</a></span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Skype: </span>
								</div>
								<div className="item-description">
									<span><a href="skype:dknet.co.il?chat">dknet.co.il</a></span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>WhatsApp:</span>
								</div>
								<div className="item-description">
									<span><a href="https://api.whatsapp.com/send?phone=15551234567&text=Hello">+972523801863</a></span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Status: </span>
								</div>
								<div className="item-description">
									<span className="processed">Processed</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Description:</h3>
					</div>
					<div className="item-body">
						<ul className="details-list">
							<li>
								<div className="item-label">
									<span>Subject: </span>
								</div>
								<div className="item-description">
									<span>Order now!</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Description: </span>
								</div>
								<div className="item-description">
									<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus nisl, efficitur sed facilisis eu, pulvinar et metus. Mauris dui sem, vulputate a fringilla et, dictum id sem. Aenean imperdiet sollicitudin placerat. Sed feugiat tristique ipsum, cursus accumsan mi imperdiet sit amet. Nunc dignissim mattis magna, eu luctus massa lacinia sit amet. Maecenas accumsan sapien id metus interdum lacinia. Nunc luctus non mauris aliquam iaculis. Aenean non sem dignissim, egestas sem et, dignissim urna. Mauris erat nisi, consectetur non dolor sit amet, tristique imperdiet enim. Phasellus porta lobortis nisl et tempus.</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Attached files:</h3>
					</div>
					<div className="item-body">
						<ol className="uploaded-files">
							<li><a href="#">HP-design.psd</a></li>
							<li><a href="#">About-design.psd</a></li>
						</ol>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Details:</h3>
					</div>
					<div className="item-body">
						<ul className="details-list two-columns">
							<li>
								<div className="item-label">
									<span>Date&time:</span>
								</div>
								<div className="item-description">
									<span>2017-12-13 20:22:24.125606</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Language: </span>
								</div>
								<div className="item-description">
									<span>en-US  (en-US, en)</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Platform: </span>
								</div>
								<div className="item-description">
									<span>Win64</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>UserAgent: </span>
								</div>
								<div className="item-description">
									<span>Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:58.0) Gecko/20100101 Firefox/58.0</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>AppVersion: </span>
								</div>
								<div className="item-description">
									<span>5.0 (Windows)</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Hardware Concurrency: </span>
								</div>
								<div className="item-description">
									<span>8</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Referer: </span>
								</div>
								<div className="item-description">
									<span></span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>UTM: </span>
								</div>
								<div className="item-description">
									<span>Source, Medium, Campaign, Term, Content</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>IP: </span>
								</div>
								<div className="item-description">
									<span>192.168.1.1</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Screen info:</h3>
					</div>
					<div className="item-body">
						<ul className="details-list two-columns">
							<li>
								<div className="item-label">
									<span>Width: </span>
								</div>
								<div className="item-description">
									<span>1920</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Height: </span>
								</div>
								<div className="item-description">
									<span>1200</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>availWidth:</span>
								</div>
								<div className="item-description">
									<span>1920</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>availHeight:</span>
								</div>
								<div className="item-description">
									<span>1160</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>colorDepth: </span>
								</div>
								<div className="item-description">
									<span>24</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>pixelDepth: </span>
								</div>
								<div className="item-description">
									<span>24</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>pixelRatio: </span>
								</div>
								<div className="item-description">
									<span>1</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Browser info:</h3>
					</div>
					<div className="item-body">
						<ul className="details-list two-columns">
							<li>
								<div className="item-label">
									<span>OSCPU: </span>
								</div>
								<div className="item-description">
									<span>Windows NT 10.0; Win64; x64</span>
								</div>
							</li>
							<li>
								<div className="item-label">
									<span>Cookie: </span>
								</div>
								<div className="item-description">
									<div className="cookie-info">
										<p>ga: <br/>undefinedGA1.2.368242806.1512027248</p>
										<p>gid: <br/>undefinedGA1.2.2141006215.1513150356</p>
										<p>PHPSESSID: <br/>undefineddtpokplf11qnqj14rroq9g84m3</p>
										<p>uc_referrer: <br/>undefineddirect</p>
										<p>uc_utm_term: <br/>undefinedShoes</p>
										<p>wp-settings-1 <br/>undefinedimgsize%3Dfull%26libraryContent%3Dbrowse%26editor%3Dhtml</p>
										<p>uc_utm_medium: <br/>undefinedqr</p>
										<p>uc_utm_source: <br/>undefinedcitilighy</p>
										<p>uc_utm_content: <br/>undefined</p>
										<p>uc_utm_campaign <br/>undefinedAutumn Collection</p>
										<p>wp-settings-time-1: <br/>undefined1508087289</p>
										<p>wordpress_test_cookie: <br/>undefinedWP+Cookie+check</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="item-section">
					<div className="item-header opener">
						<h3>Changes log:</h3>
					</div>
					<div className="item-body">
						<ul className="changes-list">
							<li>
								<span className="date">07.12.2017 - 12:01</span>
								<p><a href="#">Dmitriy Biletskiy</a> changed email from d@72dpi.co.il to dk@72dpi.co.il</p>
							</li>
							<li>
								<span className="date">07.12.2017 - 12:01</span>
								<p><a href="#">Dmitriy Biletskiy</a> added whatsapp</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</form>
	</section>
		)
		}
	}
