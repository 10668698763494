import * as React from 'react';
import { connect } from 'react-redux'

class UserBoxComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="admin-box">
				<div className="invite-user"><a href="#">invite user</a></div>
				<div className="admin"><a href="#"><img src="/assets/images/user.png" alt=""/>{this.props.email}</a>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return state.user;
}

export const UserBox = connect(mapStateToProps)(UserBoxComponent);