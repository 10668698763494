import * as React from 'react';

export class EditableText extends React.Component {
	state = {
		value: "",
		isInEditMode: false
	}

	constructor(props) {
		super(props);
		this.state.value = props.value;
	}


	changeEditMode = (e) => {
		e ? e.preventDefault(): '';
		this.setState({
			isInEditMode: !this.state.isInEditMode
		})
	}

	updateComponentValue = () => {
		this.props.change(this.refs.input.value);
		this.setState({
			isInEditMode: false,
			value: this.refs.input.value
		})
		this.changeEditMode()
	}

	keybord = (e) =>{
		if(e.keyCode == 13){
			e.preventDefault();
			this.updateComponentValue()
		}
		if(e.keyCode == 27 || e.keyCode == 9){
			e.preventDefault();
			this.changeEditMode(e)
		}
	}

	componentWillReceiveProps(nextProps, nextState){
		this.setState({
			isInEditMode: false,
			value: nextProps.value
		})
	}

	componentDidUpdate(){
		if(this.state.isInEditMode){
			this.refs.input.focus();
		}
	}

	inputField(){
		return(
			<input
				type="text"
				defaultValue={this.state.value}
				ref="input"
				onKeyDown={this.keybord}
				// onBlur={this.changeEditMode}
			/>
		)
	}

	buttons(){
		return [
			(<button className="save" onClick={this.updateComponentValue}>save</button>),
			(<button className="cancel" onClick={this.changeEditMode}>cancel</button>)
		];
	}

	renderEditView = () => {
		return <div >
			{this.inputField()}
			{this.buttons()}
		</div>
	}

	renderDefaultView = () => {
		return <div onDoubleClick={this.changeEditMode}>
			<span className="value">{this.state.value}</span>
			<a href="#" onClick={this.changeEditMode} className="edit small-btn">Edit</a>
		</div>
	}

	render() {
		return this.state.isInEditMode ?
			this.renderEditView() :
			this.renderDefaultView()

	}
}
