import * as React from 'react';
import {Header} from './parts/Header';
import {Aside} from './parts/Aside';
import {ProjectsList} from "../components/project/ProjectsList";
import {Info} from "./parts/Info";
import {Popups} from "./parts/Popups";
import {NotFound} from "./NotFound";
import { Switch, Route } from 'react-router-dom'
import { SingleLead } from '../components/lead/Single'
import { SingleProject } from '../components/project/Single'
import { SingleUser } from '../components/user/Single'
import { AddProject } from '../components/project/AddProject'
import { LeadList } from '../components/lead/LeadList'
import { UsersList } from '../components/user/UsersList'
import { connect } from 'react-redux'

import {Login} from "../components/User/Login";

export class PageComponent extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}
	skins = {
		guest : (<Login/>),
		admin : (
			<main id="page">
				<div className="overlay"></div>
				<Header/>
				<Aside/>
				<main id="main">
					<section className="work-section">
						<Switch>
							<Route exact path='/user/login/' component={Login}/>
							<Route path='/lead' component={LeadList}/>
							<Route path='/project' component={ProjectsList}/>
							<Route path='/user' component={UsersList}/>
							<Route component={ NotFound } />
						</Switch>
					</section>
					<Switch>
						<Route path='/lead/:id' component={SingleLead}/>
						<Route path='/project/add' component={AddProject}/>
						<Route path='/project/:id' component={SingleProject}/>
						<Route path='/user/:id' component={SingleUser}/>
					</Switch>
				</main>
				<Popups/>
			</main>

		),
		client : (
			<main id="page">
				<div className="overlay"></div>
				<Header/>
				<Aside/>
				<main id="main">
					<section className="work-section">
						<Switch>
							<Route exact path='/user/login/' component={Login}/>
							<Route path='/lead' component={LeadList}/>
							<Route path='/project' component={ProjectsList}/>
							<Route path='/user' component={UsersList}/>
							<Route component={ NotFound } />
						</Switch>
					</section>
					<Switch>
						<Route path='/lead/:id' component={SingleLead}/>
						<Route path='/project/add' component={AddProject}/>
						<Route path='/project/:id' component={SingleProject}/>
						<Route path='/user/:id' component={SingleUser}/>
					</Switch>
				</main>
				<Popups/>
			</main>

		)
	}


	render() {
		return this.skins[this.props.skin];
	}
}

const mapStateToProps = (state) => {
	return {
		skin: state.user.role,
		user: state.user
	};
}

export const Page = connect(mapStateToProps)(PageComponent)

