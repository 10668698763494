import * as React from 'react';

export class Definition extends React.Component {
	constructor(props) {
		super(props);
	}

	label(){
		return (
			<div className="item-label">
				<span>{this.props.label+':'}</span>
			</div>
		)
	}

	value(){
		return (
			<div className="item-description">
				<span>{this.props.value}</span>
			</div>
		)
	}

	render() {
		let container = this.props.container ? this.props.container :'div'
		return React.createElement(container,{className:'definition-item'},[this.label(),this.value()]);
	}
}