import * as React from 'react';
import {Definition} from './Definition';

export class DefinitionList extends React.Component {
	state = {
		closed : true
	};
	constructor(props) {
		super(props);
		if(props.open){
			this.state.closed = false;
		}
	}

	toggle(){
		this.setState( {closed : !this.state.closed});
	}

	render() {
		if(!this.props.items){
			return null;
		}
		let items = [];
		for(let i in this.props.items){
			if(typeof this.props.items[i] === 'object'){
				items.push((<li><DefinitionList title={i} items={this.props.items[i]}/></li>));
			}
			else {
				items.push((<Definition label={i} value={this.props.items[i]} container="li"/>));
			}
		}
		var closedClass = this.state.closed ? ' closed' : '';
		return (
			<div className={'item-section ' + this.props.className + closedClass}>
				<div className="item-header opener" onClick={this.toggle.bind(this)}>
					<h3>{this.props.title}</h3>
				</div>
				<div className="item-body">
					<ul className="details-list">
						{items}
					</ul>
				</div>
			</div>
		)
	}
}