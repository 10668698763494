import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {Storage} from  './storage/Storage'

import {Page} from './containers/Page';
import { BrowserRouter,Route} from 'react-router-dom';


import * as constants from './constants';
ReactDOM.render(
	<Provider store={Storage}>
		<BrowserRouter>
			<Route component={Page} />
		</BrowserRouter>
	</Provider>
	,
    document.getElementById(constants.APP_CONTAINER_ID)
);