import * as React from 'react';
import {User} from "../../actions/user";
import {connect} from "react-redux";
import {UserListItem} from "./UserListItem";
import {PopupsCollection} from "../../containers/parts/Popups";
import {addUser} from "./addUser";

PopupsCollection.add('addUser',addUser);

class UserListComponent extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	addUser(){
		PopupsCollection.activate('addUser');
	}

	loading = (
		<div className="loading">
			все пропало
		</div>
	);

	render() {
		console.log(this.props);
		if(!this.props.lastUpdate){
			User.get();
			return this.loading;
		}
		var items = [];
		var current = parseInt(this.props.location.pathname.replace('/user/','')); //TODO: так себе
	    for (let id in this.props.users){
	    	var data =  Object.assign({},this.props.users[id],{
	    		active : id == current
	    	}) ;
	    	items.push(React.createElement(UserListItem,data));
	    }
		return (
			<section id="users-management">
				<ul className="controls">
					<li><button className="icon-user-plus small-btn" onClick={this.addUser}></button></li>
				</ul>
				<table id="leads-table">
				<thead>
					<tr>
						<th className="field-id">ID</th>
						<th className="field-user-name">Name</th>
						<th className="field-lead-email">Email</th>
						<th className="status">Role</th>
					</tr>
				</thead>
				<tbody>
					{items}
				</tbody>
			</table>
			</section>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		users : state.users.items,
		lastUpdate : state.users.lastUpdate
	};
}

export const UsersList = connect(mapStateToProps)(UserListComponent)
