import * as React from 'react';
import { Link } from 'react-router-dom'
import {UserBox} from "../../components/User/UserBox";

export class Header extends React.Component {
	state = {};

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<header id="header">
				<strong className="logo"><a href="#"><img src="/assets/images/logo.svg" alt="" /></a></strong>
				<UserBox/>
				<div className="admin-box-mobile-link"><a href="#"><img src="/assets/images/user.png" alt="" /></a></div>
				<div className="button-mobile-menu"></div>
				<nav id="main-navigation">
					<ul>
						{/*<li><a href="#">Queue</a></li>*/}
						{/*<li><a href="#">Current</a></li>*/}
						{/*<li><a href="#">Archive</a></li>*/}
						{/*<li><a href="#">Reports</a></li>*/}
						{/*<li><a href="#">Schedule</a></li>*/}
						<li><Link to="/project/">Projects</Link></li>
						<li><Link to="/lead/">leads</Link></li>
						<li><Link to="/user/">user</Link></li>
					</ul>
				</nav>
			</header>
		);
	}
}
