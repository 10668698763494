import * as React from 'react';
import {File} from './File';

export class FileList extends React.Component {
	state = {
		closed : false
	};

	constructor(props) {
		super(props);
	}

	toggle(){
		this.setState( {closed : !this.state.closed});
	}

	render() {
		var files = [];
		for(let i=0; i < this.props.files.length ; i++){
			files.push(React.createElement(File,{
				base : this.props.base,
				position : i,
				file : this.props.files[i]
			}));
		}
		var closedClass = this.state.closed ? ' closed' : '';
		return (
			<div className={"item-section"+closedClass}>
				<div className="item-header opener" onClick={this.toggle.bind(this)}>
					<h3>Attached files:</h3>
				</div>
				<div className="item-body">
					<ol className="uploaded-files">
						{files}
					</ol>
				</div>
			</div>
		)
	}
}