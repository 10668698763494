import * as React from 'react';
import { connect } from 'react-redux'
import {FileList} from '../file/FileList'
import {DefinitionList} from '../../containers/Definition/DefinitionList';

const basePatern = '/lead/{id}/';

class SingleLeadComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	details(){
		return(
			<div className="item-section">
				<DefinitionList open={true} title ="Details:" items={this.props.lead.additional}/>
			</div>
		)
	}

	render() {
		var id =this.props.match.params.id;
		var base = basePatern.replace('{id}',id);
		var lead = Object.assign({},this.props.lead);
		delete lead['files'];
		delete lead['additional'];
		delete lead['browser'];
		delete lead['utm'];
		delete lead['id'];
		return (
				<section className="info-section">
					<form action="post">
						<header className="info-section-headline">
							<div className="action-bar">
								<div className="select-field">
									<select name="task-options" id="lead-status">
										<option value="">Change status</option>
										<option value="active">Active</option>
										<option value="processed">Processed</option>
										<option value="not-processed">Not processed</option>
									</select>
								</div>
								<a href="#" className="chat small-btn">Chat</a>
								<a href="#" className="edit small-btn">Edit</a>
							</div>
							<h2 className="user-name">{this.props.lead.name}</h2>
						</header>
						<div className="issue-info-item details">
							<DefinitionList title="Main inforamation:" items={lead} className="browser-info" twoColumns={true}/>
							{this.details()}
							<FileList files={this.props.lead.files} base={base}/>
							<DefinitionList title="Browser info:" items={this.props.lead.browser} className="browser-info"/>
							<DefinitionList title="Utm:" items={this.props.lead.utm}/>
							<div className="item-section">
								<div className="item-header opener">
									<h3>Changes log:</h3>
								</div>
								<div className="item-body">
									<ul className="changes-list">
										<li>
											<span className="date">07.12.2017 - 12:01</span>
											<p><a href="#">Dmitriy Biletskiy</a> changed email from d@72dpi.co.il to dk@72dpi.co.il</p>
										</li>
										<li>
											<span className="date">07.12.2017 - 12:01</span>
											<p><a href="#">Dmitriy Biletskiy</a> added whatsapp</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</form>
				</section>
			);
		}
}


const mapStateToProps = (state , ownProps) => {
	return {
		lead: state.leads.items[ownProps.match.params.id]
	};
}

export const SingleLead = connect(mapStateToProps)(SingleLeadComponent);

