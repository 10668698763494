import * as React from 'react';
import {Storage} from  '../../storage/Storage'
import {User} from '../../actions/user';

export class Login extends React.Component {
	state = {
		email : '',
		password :''
	};

	constructor(props) {
		super(props);
		this.handleSubmit = this.login.bind(this);
	}

	login(e){
		e.preventDefault();
		if(this.validate()){
			User.login(this.state).then(function (Result) {
				//alert(Result);
			},function (Result) {
				//alert(Result+'):');
			});
		}

		return false
	}


	validate(){
		return true;
		// TODO : сделать валидацию
	}

	render() {
		return (
			<div className="login-popup">
				<form action="/user/login" method="post" onSubmit={this.handleSubmit}>
					<input
						onChange = {(event) => this.setState({email:event.target.value})}
					type="email" placeholder="Email" />
					<input type="password"
						onChange = {(event) => this.setState({password:event.target.value})}
					placeholder="Password" />
					<input type="submit" value="Login" />
				</form>
				<a href="#" className="close-btn">Close</a>
			</div>
		)
	}
}
