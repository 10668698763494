import * as React from 'react';
import {EditableText} from "./EditableText";


export class EditableSelect extends EditableText {

	options(){
		var options = []
		if(this.props.emptyText){
			options.push((<option value="">{this.props.emptyText}</option>))
		}
		for(var val in this.props.options){
			options.push((<option value={val}>{this.props.options[val]}</option>))
		}
		return(options)
	}

	inputField(){
		return(
			<div className="select-field">
				<select
						ref="input"
						defaultValue={this.state.value}
						onKeyDown={this.keybord}
				>
					{this.options()}
				</select>
			</div>
		)
	}
}