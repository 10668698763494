export const Gate = {

	get : function (url, options) {
		var _options = this.parseOptions(options);
		_options.url = url;
		return new Promise(function(resolve, reject){
			_options.success = resolve;
			_options.error = reject;
			var  requests = new ajaxRequest(_options);
			this.requests.push(requests);
		}.bind(this));
	},

	post : function (url, options) {
		options.method = 'POST';
		return this.get(url, options);
	},

	parseOptions : function (options) {
		var _options = Object.assign({}, this.defaults, options);
		_options.headers = Object.assign({}, this.defaults.headers, options.headers);
		return _options
	},

	defaults : {
		responseType : 'json',
		method : 'GET',
		headers : {
			Accept : 'application/json'
		},
		data : {}
	},

	requests : []


}

class ajaxRequest {
	constructor(options){
		this.request = new XMLHttpRequest();
		this.options= options;
		this.setHeaders();
		this.parseData();
		this.send();
	}

	setHeaders(){
		this.request.open(this.options.method, this.options.url);
		this.request.responseType = this.options.responseType;
		for(let header in this.options.headers){
			this.request .setRequestHeader(header, this.options.headers[header]);
		}
	}

	parseData(){
		this.data = new FormData();
		for(let key in this.options.data){
			this.data.append(key , this.options.data[key]);
		}

	}

	stateChange(event) {
		if (event.target.readyState == 4) {
			var request = event.target
			if (request.status == 200 && this.options.success) {
				var response = request.response;
				if(this.options.responseType == 'json' && typeof response !== "object"){
					response = JSON.parse(response);
				}
				this.options.success(response)
			} else if(this.options.error) {
				this.options.error()
			}
		}
	}

	send(){
		this.request.onreadystatechange = this.stateChange.bind(this);
		this.request.send(this.data);
	}


}