import * as React from 'react';
import {LeadListItem} from './leadListItem';
import { connect } from 'react-redux';
import {Lead} from '../../actions/lead';

class LeadListComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	loading = (
		<div className="loading">
			все пропало
		</div>
	);

	render() {
		if(!this.props.lastUpdate){
			Lead.get();
			return this.loading;
		}
	    var items = [];
		var current = parseInt(this.props.location.pathname.replace('/lead/','')); //TODO: так себе
	    for (let id in this.props.leads){
	    	var data =  Object.assign({},this.props.leads[id],{
	    		active : id == current
	    	}) ;
	    	items.push(React.createElement(LeadListItem,data));
	    }
		return (
			<table id="leads-table">
			<thead>
				<tr>
					<th className="field-id">ID</th>
					<th className="field-lead-name">Lead Name</th>
					<th className="field-lead-phone">Date</th>
					<th className="field-lead-email">Email</th>
					<th className="manager">Manager Name</th>
					<th className="status">Status</th>
				</tr>
			</thead>
			<tbody>
				{items}
				<tr>
					<td className="field-id">1</td>
					<td className="field-lead-name">Dmitriy Kagan</td>
					<td className="field-lead-phone"><a href="tel:+972523801863">+972523801863</a></td>
					<td className="field-lead-email"><a href="mailto:dk@72dpi.co.il">dk@72dpi.co.il</a></td>
					<td className="manager">Elena Biletskaya</td>
					<td className="status"><span className="processed">Processed</span></td>
				</tr>
				<tr>
					<td className="field-id">2</td>
					<td className="field-lead-name">Bar Golan</td>
					<td className="field-lead-phone"><a href="tel:09500000">09500000</a></td>
					<td className="field-lead-email"><a href="mailto:bar@goodmedia.co.il">bar@goodmedia.co.il</a></td>
					<td className="manager"><a href="#" className="assign-manager">Assign manager</a></td>
					<td className="status"><span className="not-processed">Not processed</span></td>
				</tr>
				<tr>
					<td className="field-id">2</td>
					<td className="field-lead-name">Bar Golan</td>
					<td className="field-lead-phone"><a href="tel:09500000">09500000</a></td>
					<td className="field-lead-email"><a href="mailto:bar@goodmedia.co.il">bar@goodmedia.co.il</a></td>
					<td className="manager"><a href="#" className="assign-manager">Assign manager</a></td>
					<td className="status"><span className="active">Active</span></td>
				</tr>
			</tbody>
		</table>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		leads: state.leads.items,
		lastUpdate: state.leads.lastUpdate
	};
}

export const LeadList = connect(mapStateToProps)(LeadListComponent)

