import * as React from 'react';
import { withRouter } from 'react-router-dom';
import {Project} from "../../actions/project";
import {PopupsCollection} from "../../containers/parts/Popups";

class AddProjectPopup extends React.Component {
	state = {
		title :'',
		owner : 0
	};

	constructor(props) {
		super(props);
	}

	save = (e) => {
		e.preventDefault();
		if(this.validate()){
			console.log(this.state);
			Project.add({title : this.state.title}).then(this.afterAdd);
		}
		return false;
	}

	afterAdd = (project) =>{
		this.props.history.push('/project/'+project.id);
		this.close();
	}

	validate(){
		return true;
	}

	close = (e)=>{
		e && e.preventDefault();
		PopupsCollection.deactivate()
	}

	render() {
		return (
			<div className="login-popup">
				<form action="" method="post" onSubmit={this.save.bind(this)}>
					<div className="row">
						<label>Title</label>
						<input type="text"
							onChange= {(e) => this.setState({title : e.target.value})}
						name="title" />
					</div>
					{/*<div className="row">*/}
						{/*<label>Owner</label>*/}
						{/*<select name="owner"*/}
							{/*onChange= {(e) => this.setState({owner : e.target.options[e.target.selectedIndex].value})}*/}
						{/*>*/}
							{/*<option value="1">1</option>*/}
							{/*<option value="1">2</option>*/}
							{/*<option value="1">3</option>*/}
						{/*</select>*/}
					{/*</div>*/}
					<div className="row">
						<input onClick={this.save} type="submit" value="add" />
					</div>
				</form>
				<a href="#" className="close-btn" onClick={this.close}>Close</a>
			</div>
		)
	}
}
export const AddProject=withRouter(AddProjectPopup);

// TODO: попап надо систематизировоть
