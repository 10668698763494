import * as React from 'react';
import {connect} from "react-redux";
import {Storage} from "../../storage/Storage";

export const PopupsCollection = {
	add : function (type,classItem) {
		this.collection[type] = React.createElement(classItem);
	},
	get : function(type){
		return this.collection[type] ? this.collection[type] : null;
	},
	activate : function(type){
		Storage.dispatch({
			type: 'setActivePopup',
			for : 'Page',
			popup: type
		})
	},
	deactivate(){
		Storage.dispatch({
			type: 'setActivePopup',
			for : 'Page',
			popup: ''
		})
	},
	collection : {}
}
class PopupsComponent extends React.Component {
	state = {
		active : null
	};

	constructor(props) {
		super(props);
	}

	setActive(props){
		this.setState({
			active: PopupsCollection.get(props.activePopup)
		})
	}

	componentWillReceiveProps(nextProps, nextState){
		this.setActive(nextProps);
	}

	render() {
		return (
			<div className="popup-holder">{this.state.active}</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		activePopup : state.page.activePopup
	};
}

export const Popups = connect(mapStateToProps)(PopupsComponent)
