import {CollectionToObject, Storage} from '../storage/Storage'
import {Gate} from '../libs/gate'

export const User = {
	login : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user/login',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					Storage.dispatch({
						type: 'update_user',
						user: response.model
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	},
	get : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					Storage.dispatch({
						type: 'load',
						for : 'Users',
						items: CollectionToObject(response.model)
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	},
	add : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user/add',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					Storage.dispatch({
						type: 'updateSingle',
						for : 'Users',
						item: response.model
					});
					resolve(response.model);
				} else {
					reject('нефига');
				}
			});
		});
	},
	updateProfile : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user/updateProfile',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					console.log(response);
					Storage.dispatch({
						type: 'updateSingle',
						for : 'Users',
						item: response.model
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	},
	updateEmail : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user/updateEmail',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					console.log(response);
					Storage.dispatch({
						type: 'updateSingle',
						for : 'Users',
						item: response.model
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	},
	updatePassword : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/user/updatePassword',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					console.log(response);
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	}
}

export const Lead = {
	test : 'test'
}

