import * as React from 'react';
import {PopupsCollection} from "../../containers/parts/Popups";
import {User} from "../../actions/user";
import { withRouter } from 'react-router-dom'
import {Validator} from "../../libs/Validator";

class addUserPopup extends React.Component {
	state = {
		email : '',
		password :'',
		role :'',
		errors : {}
	};

	messages = {
		wrongEmail : 'invalid email',
		incorrectPassword : 'коротковат парольчик однако',
		require : 'поле обязательно'
	}

	constructor(props) {
		super(props);
	}

	afterAdd = (user) =>{
		this.props.history.push('/user/'+user.id);
		this.close();
	}

	addUser = (e)=>{
		e && e.preventDefault();
		if(!this.validate()){
			console.log(this.state);
			return;
		}
		var data = Object.assign({});
		delete data.errors;
		User.add(this.state).then(this.afterAdd);
	}

	validate(){
		var errors = {};
		if(!Validator.email(this.state.email)){
			errors.email = Validator.wrapError(this.messages.wrongEmail);
		}
		if(!Validator.password(this.state.password)){
			errors.password = Validator.wrapError(this.messages.incorrectPassword);
		}
		if(!Validator.notEmpty(this.state.role)){
			errors.role = Validator.wrapError(this.messages.require);
		}
		this.setState({
			errors : errors
		});
		// TODO: сделать валихацию
		return !Object.keys(errors).length;
	}




	close = (e)=>{
		e && e.preventDefault();
		PopupsCollection.deactivate()
	}

	render() {
		return (
			<div className="login-popup">
				<form action="/user/add" method="post" onSubmit={this.addUser}>
					<input
						onChange = {(event) => this.setState({email:event.target.value})}
					type="email" placeholder="Email" />
					{this.state.errors.email}
					<input type="text"
						onChange = {(event) => this.setState({password:event.target.value})}
					placeholder="Password" />
					{this.state.errors.password}
					<div className="select-field">
						<select name="role" value={this.state.role} onChange={(event) => this.setState({role:event.target.value})}>
							<option value="">select role</option>
							<option value="client">client</option>
							<option value="manager">manager</option>
							<option value="finance">finance</option>
							<option value="developer">developer</option>
							<option value="team-leader">team-leader</option>
							<option value="admin">admin</option>
						</select>
					</div>
					{this.state.errors.role}
					<input type="submit" value="Create" />
				</form>
				<a href="#" className="close-btn" onClick={this.close}>Close</a>
			</div>
		)
	}
}

export const addUser=withRouter(addUserPopup);
