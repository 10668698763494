import {Storage} from  '../storage/Storage'
import {CollectionToObject} from  '../storage/Storage'
import {Gate} from '../libs/gate'

export const Project= {
	get : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/project',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					Storage.dispatch({
						type: 'load',
						for : 'Project',
						items: CollectionToObject(response.model)
					});
					resolve('успешно');
				} else {
					reject('нефига');
				}
			});
		});
	},
	add : function (data) {
		return new Promise(function (resolve, reject){
			Gate.post('/project/add',{
				data : data
			}).then(function (response) {
				if (response && response.success) {
					console.log(response);
					Storage.dispatch({
						type: 'update',
						for : 'Project',
						items: {[response.model.id] : response.model}
					});
					resolve(response.model);
				} else {
					reject(response);
				}
			});
		});
	}
}
