import * as React from 'react';
export const Validator = {

	paterns : {
		email : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},

	email : function(str) {
		return this.paterns.email.test(String(str).toLowerCase());
	},
	wrapError : function (str) {
		return (<span class="error validation-error">{str}</span>);
	},
	notEmpty(str){
		return str.trim()
	},

	password : function (str) {
		return str.length >= 6
	}
}