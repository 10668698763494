let guest = {
	role : 'guest'
}

export function User(state = guest, action) {
	switch (action.type) {
		case 'update_user':
			return Object.assign({}, state, action.user);
	}
	return state
};