import { createStore } from 'redux';
import {reducers} from './reducers/reducers'

function startStatePrepear(state) {
	if(state){
		if(state.leads){
			if(!Array.isArray(state.leads.items)){
				state.leads.items = [state.leads.items]
			}
			state.leads.items = CollectionToObject(state.leads.items);
		}
		if(state.projects){
			if(!Array.isArray(state.projects.items)){
				state.projects.items = [state.projects.items]
			}
			state.projects.items = CollectionToObject(state.projects.items);
		}
		if(state.users){
			if(!Array.isArray(state.users.items)){
				state.users.items = [state.users.items]
			}
			state.users.items = CollectionToObject(state.users.items);
		}
	}
	return state;
}

export const Storage = createStore(
	reducers,
	startStatePrepear(window.startState),
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export function CollectionToObject(arr) {
	let temp = {};
	for(let i=0; i < arr.length; i++){
		temp[arr[i].id] = arr[i];
	}
	return temp;
}