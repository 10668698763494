import { combineReducers } from 'redux'
import {Project} from './project'
import {Lead} from './lead'
import {User} from './user'
import {Page} from './page'
import {Users} from './users'
export const  reducers = combineReducers({
	leads : Lead,
	projects : Project,
	users : Users,
	user : User,
	page : Page
});