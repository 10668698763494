import * as React from 'react';

export class File extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			// TODO: доработать ревизии
			<li><a href={this.props.base+'download/'+this.props.position}>{this.props.file.name}</a></li>
		)
	}
}