let page = {
	activePopup : ''
}

export function Page(state = page, action) {
	if(action.for == 'Page'){
		switch (action.type) {
			case 'setActivePopup':
				return Object.assign({}, state, {activePopup: action.popup});
		}
	}
	return state
};