const DefaultLeadState ={
	filters : {

	},
	items : [],
	lastUpdate : false
}
export function Project(state = {} , action){
	if(action.for == 'Project'){
		switch (action.type) {
			case 'load':
				return Object.assign({}, state, {
					items:action.items,
					lastUpdate : new Date()
				});
			case 'update' :{
				return Object.assign({}, state, {
					items: Object.assign({},state.items,action.items)
				});
			}
		}
		return state;
	}
	state = Object.assign({},DefaultLeadState,state)
	return state;
};